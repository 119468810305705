<template>
  <LayoutThumbnailComponent
    linkName="focus-week-slug"
    :localizedData="localizedData"
    :linkType="linkType"
    :class="$style.root"
    :image="coverImage"
    :clusterBackgroundColor="clusterBackgroundColor"
  >
    <template
      #tags
      v-if="cluster?.titles || categories?.length"
    >
      <SharedLinkToClusterComponent
        v-if="cluster"
        :data="cluster"
        data-narrow
        linkType="dynamic"
        sizeOption="tiny"
      />
    </template>

    <div :class="$style.title">
      {{ title }}
    </div>

    <CoreDateTimeRangeComponent
      :dateStart="attributes?.date_start"
      :dateEnd="attributes?.date_end"
      :class="$style.dateRange"
    />
  </LayoutThumbnailComponent>
</template>

<script setup>
import { uniq } from 'lodash-es'
const route = useRoute()

const props = defineProps({
  data: Object,
  linkType: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'dynamic'].includes(value)
  }
})

const attributes = computed(() => {
  return baseStrapiGetAttributes(props?.data)
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(attributes?.value)
})

const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))

const coverImage = computed(() => localizedData.value?.cover_image?.data?.attributes)

const cluster = computed(() => {
  return localizedData.value?.city_entry?.data?.attributes?.cluster_entry?.data?.attributes
})

const clusterBackgroundColor = computed(() => {
  return cluster?.value?.background_color
})
</script>

<style module>
.root {
  --layout--thumbnail--aspect-ratio: .65;
}

.title {
  composes: font-size-small from global;
  margin-top: var(--unit--spacer);
}

.dateRange {
  composes: font-size-x-small from global;
  color: var(--color--blue);
  display: block;
}
</style>
