import qs from 'qs'

export const queryFocusWeekEntryThumbnails = (options: QueryManyOptions) => {
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: options?.sort || ['date_start:asc', 'date_end:asc', `${sortKey}:asc`],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 20,
      },
      filters: options?.filters || [],
      ...thumbnailFocusWeekEntry
    },
    {
      encodeValuesOnly: true
    }
  )

  return `focus-week-entries?${query}`
}
