export const thumbnailFocusWeekEntry = {
  populate: {
    ...queryFragmentCollectionTypeDefault,
    cover_image: true,
    address: true,
    city_entry: {
      ...queryFragmentCollectionTypeDefault,
      cluster_entry: {
        populate: {
          ...queryFragmentCollectionTypeDefault,
        }
      }
    }
  }
}
